<template>
	<div class="wrap">
		<audio ref="getVoice">
			<source src="../assets/voice/voice.mp3" type="audio/mpeg">
		</audio>
		<!-- tabbar -->
		<div class="tabBar">
			<el-dropdown trigger="click" class="selLang" @command="changeJhe">
				<span class="el-dropdown-link">
					<i class="el-icon-s-unfold" size='40'></i>
				</span>
				<el-dropdown-menu slot="dropdown" style="left: 10px !important;">
					<el-dropdown-item v-for="item in jiheList" :key="item.value" :command="item">{{ item.label }}
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<img class="img-logo" :src="pcLogoUrl" alt="">
			<el-dropdown trigger="click" class="tr selLang">
				<span class="el-dropdown-link"></span>
			</el-dropdown>
			<!-- <el-dropdown trigger="click" class="tr selLang" @command="changeLaguages">
				<span class="el-dropdown-link">
					{{selLanguage.label}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item v-for="item in langOptions" :key="item.value" :command="item">{{item.label}}
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
		</div>
		<div class="main">
			<div class="flex flex-js-around top-box">
				<img src="../assets/mobileimages/quiz_bg.jpg" alt="" />
			</div>
			<div class="quiz-pic">
				<img class="quiz-bg" src="../assets/mobileimages/quiz_02.png" alt="" />
				<div class="pic-box">
					<div class="flex flex-js-around">
						<img v-if="num_a==99" src="../assets/mobileimages/quiz_01.png" alt="" /> <img v-else
							:src="require('../assets/num/'+num_a+'.png')" alt="" />
						<img v-if="num_b==99" src="../assets/mobileimages/quiz_01.png" alt="" /> <img v-else
							:src="require('../assets/num/'+num_b+'.png')" alt="" />
						<img v-if="num_c==99" src="../assets/mobileimages/quiz_01.png" alt="" /> <img v-else
							:src="require('../assets/num/'+num_c+'.png')" alt="" />
						<img v-if="num_d==99" src="../assets/mobileimages/quiz_01.png" alt="" /> <img v-else
							:src="require('../assets/num/'+num_d+'.png')" alt="" />
					</div>
				</div>
			</div>
			<div class="input-box">
				<div class="flex item-box flex-js-between">
					<div>
						<div class="title">เลขท้าย2ตัวแรก</div>
						<div><input class="item-inp pr" type="number" placeholder="" v-model="first_2" /></div>
					</div>
					<div>
						<div class="title txt">เลขท้าย2ตัวหลัง</div>
						<div><input class="item-inp pl" type="number" placeholder="" v-model="last_2" /></div>
					</div>
				</div>
				<div>
					<div class="title">ยูสเซอร์เนมสมาชิก</div>
					<div><input class="item-inp" type="text" placeholder="" v-model="username" /></div>
				</div>
			</div>

			<div class="btn-box" @click="submit()">ส่ง</div>

			<div class="record" @click="isQueryDia = true">ประวัติทายผล</div>

			<div class="tip">ข้อกำหนดและเงื่อนไขกิจกรรม:</div>

			<div class="con">
				1. จำกัด VIP2 ขึ้นไปในการเข้าร่วมในกิจกรรมนี้<br />
				2. ยูสเซอร์เนมสามารถทำนายได้ 1 ครั้ง / 1 หมายเลขในแต่ละงวด เท่านั้น<br />
				3. ออกรางวัลตามเลขท้ายสองตัวในผลการจับสลากกินแบ่งรัฐบาลอย่างเป็นทางการ<br />
				4. ทายเลขสองตัวแรกหรือสองตัวหลังเพียงอย่างเดียว รับโบนัส 500 บาท
				และถอนเงินได้โดยตรงไม่ต้องทำเทิร์น<br />
				5. หากเลขสองตัวแรกและสองตัวท้ายทายถูกพร้อมกัน จะได้รับโบนัส 1000 บาท
				และสามารถถอนเงินได้โดยตรงไม่ต้องทำเทิร์น<br />
				6. เวลาสิ้นสุดการทำนายคือวันที่ 15 และ 30 ของแต่ละเดือน<br />
				7.โปรโมชั่นนี้ขอสงวน.สิทธิ์ ต่อ 1 ชื่อผู้ใช้/1 สิทธิ์/1 IP address/1 อุปกรณ์/ครัวเรือน กรณีสมรู้ร่วมคิด
				ฉ้อโกง หรือใช้หลายบัญซี คุณจะถูกตัดสิทธิ์ทันทีโดยไม่มีข้อยกเว้น และบัญชีของคณหรือสมาชิกคนอื่นๆ
				จะถูกระงับโดยอัตโนมัติ
			</div>

			<div class="con">
				FAW99 ขอสงวนสิทธิ์การเปลี่ยนแปลง แก้ไข และยกเลิกโปรโมชั่นโดยไม่แจ้งให้ทราบล่วงหน้า
			</div>

			<div class="h20"></div>


			<!-- 弹窗 -->
			<div class="cover" v-if="isQueryDia"></div>
			<div class="dialog-query" v-if="isQueryDia">
				<div class="dia-top">
					<span class="t-queryresult">สอบถามสมาชิก</span>
					<img src="../assets/mobileimages/close.png" alt="" @click="isQueryDia = false">
				</div>
				<div>หมายเลขแบบทดสอบสมาชิกที่คุณกําลังมองหาคือ</div>
				<div class="flex item-box flex-js-between">
					<div>
						<div>สองอันดับแรก</div>
						<div class="num">{{p1_num}}</div>
					</div>
					<div>
						<div>หลังสองตำแหน่ง</div>
						<div class="num">{{p2_num}}</div>
					</div>
				</div>
				<div class="txt-a">ผลการจับสลาก:<b class="txt-b">ไม่ชนะ</b></div>
				<div><input class="item-inps" type="text" placeholder="ป้อนหมายเลขสมาชิกที่คุณต้องการสอบถาม"
						v-model="pusername" /></div>
				<div class="btn-boxs" @click="search()">ส่ง</div>
			</div>
		</div>
	</div>
</template>


<script>
	export default {
		name: "wrap",
		components: {},
		data() {
			return {
				nowLang: "th",
				selLanguage: {},
				pcLogoUrl: "",
				// langOptions: [{
				// 		value: "th",
				// 		label: "ไทย",
				// 		icon: "flag_thai.png",
				// 		currency: 'THB',
				// 	},
				// 	{
				// 		value: "en",
				// 		label: "English",
				// 		icon: "flag_america.png",
				// 		currency: 'THB',
				// 	},
				// 	{
				// 		value: "km",
				// 		label: "កម្ពុជា។",
				// 		icon: "flag_cambodia.png",
				// 		currency: 'USD',
				// 	},
				// 	{
				// 		value: "zh",
				// 		label: "中文",
				// 		icon: "flag_china.png",
				// 		currency: 'USD',
				// 	},
				// ],
				lang: "",
				footData: [],

				username: '',
				first_2: '',
				last_2: '',
				id: "",
				num_a: 99,
				num_b: 99,
				num_c: 99,
				num_d: 99,
				isQueryDia: false,
				pusername: '',
				p1_num: '??',
				p2_num: '??',
			};
		},
		computed: {
			jiheList() {
				let list = []
				list.push({
					id: null,
					value: "",
					label: this.$t("lang.VIPquery"),
				}, )
				this.lang == "zh" && list.push({
					value: this.footData.zw_home_url,
					label: this.$t("lang.officialIndex"),
				}, )
				this.lang == "en" && list.push({
					value: this.footData.yy_home_url,
					label: this.$t("lang.officialIndex"),
				}, )
				this.lang == "th" && list.push({
					value: this.footData.ty_home_url,
					label: this.$t("lang.officialIndex"),
				}, )
				this.lang == "km" && list.push({
					value: this.footData.jpz_home_url,
					label: this.$t("lang.officialIndex"),
				}, )

				this.lang == "zh" && list.push({
					value: this.footData.zw_promotion_url,
					label: this.$t("lang.specialOffer"),
				}, )
				this.lang == "en" && list.push({
					value: this.footData.yy_promotion_url,
					label: this.$t("lang.specialOffer"),
				}, )
				this.lang == "th" && list.push({
					value: this.footData.ty_promotion_url,
					label: this.$t("lang.specialOffer"),
				}, )
				this.lang == "km" && list.push({
					value: this.footData.jpz_promotion_url,
					label: this.$t("lang.specialOffer"),
				}, )
				this.lang == "th" && list.push({
					id: 3,
					value: location.origin,
					label: this.$t("lang.quizNav"),
				}, )
				this.footData.cs_url && list.push({
					value: this.footData.cs_url,
					label: this.$t("lang.onlineCustomerService"),
				}, )
				return list
			}
		},
		created() {
			this.getList();
			let queryLang = this.$route.query.lang;
			let lang = localStorage.getItem("language") || "th";
			if (queryLang == "th" || queryLang == "en" || queryLang == "km" || queryLang == "zh") {
				lang = queryLang;
			}
			this.nowLang = lang;
			this.$i18n.locale = lang;
			this.lang = lang
			// this.selLanguage = this.langOptions.find(item => {
			// 	return item.value == this.$i18n.locale;
			// });
			this.getWebInfo();
		},
		mounted() {

		},
		methods: {
			getList() {
				this.$get("/api/Lottery/index").then(res => {
					if (res.data.data.first_2) {
						this.num_a = res.data.data.first_2.substr(0, 1)
						this.num_b = res.data.data.first_2.substr(1, 1)
					}
					if (res.data.data.last_2) {
						this.num_c = res.data.data.last_2.substr(0, 1)
						this.num_d = res.data.data.last_2.substr(1, 1)
					}
					this.id = res.data.data.id
				})
			},
			changeJhe(item) {
				if (item.id == 3) {
					return
				} else {
					location.href = item.value
				}
			},
			// changeLaguages(item) {
			// 	this.$i18n.locale = item.value;
			// 	this.selLanguage = item;
			// 	localStorage.setItem("language", item.value);
			// 	this.$router.replace("/");
			// 	location.reload();
			// },
			//网站信息
			getWebInfo() {
				this.$get("/api/sysconf.html").then(res => {
					if (res.data.code == 0) {
						this.footData = res.data.data;
						this.pcLogoUrl = this.footData.pc_logo;
					}
				});
			},

			submit() {
				if (!this.first_2) {
					alert("กรุณากรอกตัวเลข 2 หลักแรก")
					return
				} 
				if (!this.last_2) {
					alert("กรุณากรอกตัวเลข 2 หลักสุดท้าย")
					return
				} 
				if (!this.username){
					alert("กรุณากรอกชื่อผู้ใช้")
					return
				} 
				this.$get("/api/Lottery/bet", {
					username: this.username,
					first_2: this.first_2,
					last_2: this.last_2,
					id: this.id,
				}).then(res => {
					//if (res.data.code == 1) {
					alert(res.data.msg)
					//}
				})
			},

			search() {
				this.$get("/api/Lottery/Query", {
					username: this.pusername,
				}).then(res => {
					if (res.data.code == 0) {
						this.p1_num = res.data.data.first_2
						this.p2_num = res.data.data.last_2
					} else {
						alert(res.data.msg)
					}
				})
			}
		},
		watch: {
			dataList1: function() {
				this.$nextTick(function() {
					let tableBox = this.$refs.tableBox;
					let tableBoxFather = this.$refs.tableBoxFather;
					tableBoxFather.style.height = tableBox.clientHeight * 0.67 + "px";
				});
			}
		}
	};
</script>

<style scoped lang="scss">
	.wrap {
		background-color: #1b072a;

		.tabBar {
			display: flex;
			justify-content: space-between;
			padding: .6rem 6px;

			.img-logo {
				width: 30%;
			}

			.el-icon-s-unfold {
				color: #fff;
				font-size: 24px;
			}

			a {
				color: #fff;
				text-decoration: none;
			}

			#positonBox {
				position: relative;
			}

			div {
				height: 2rem;
				color: #fff;
				flex: auto;
				line-height: 2rem;
				font-size: 12px;

				&.tr {
					text-align: right;
				}

				span {
					display: inline-block;
					line-height: 30px;
				}

				.click {
					border-bottom: 1px solid #fffe00;
					font-size: 14px;
				}

				.icon {
					position: absolute;
					top: 3px;
					right: 2px;
					display: inline-block;
					width: 10px;
					height: 10px;
					background-image: url(../assets/mobileimages/line.png);
					background-size: 100%;
				}
			}
		}

		.main {
			width: 100%;
			min-height: 100%;
			margin: 0 auto;
			font-size: 24px;
			color: #fff;

			.top-box {
				img {
					width: 100%;
					height: auto;
				}
			}

			.quiz-pic {
				height: 14rem;
				position: relative;

				.quiz-bg {
					width: 100%;
					height: auto;
				}

				.pic-box {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					margin: auto;
					width: 80.4%;

					img {
						width: 24%;
						height: 30%;
						margin-top: 9%;

						&:not(:last-child) {
							margin-right: 1%;
						}
					}
				}
			}

			.input-box {
				margin: 0 2rem;
				position: relative;

				.title {
					font-size: 18px;
					color: #e2e2ff;
					margin-bottom: 0.5rem;
				}

				.item-inp {
					width: 96%;
					height: 2rem;
					background-color: #000000;
					border-radius: 0.5rem;
					border: solid 0.1rem #b424f5;
					padding: 0 0.5rem;
				}

				.txt {
					margin-left: 1rem;
				}

				.pr {
					margin-right: 1rem;
					width: 82%;
					margin-bottom: 1rem;
				}

				.pl {
					width: 82%;
					margin-left: 1rem;
					margin-bottom: 1rem;
				}

				input {
					color: #fff !important;
				}
			}

			.btn-box {
				margin: 2rem 30%;
				text-align: center;
				line-height: 3rem;
				width: 10rem;
				height: 3rem;
				background-color: #b424f5;
				border-radius: 0.5rem;
				border: solid 0rem #b424f5;
			}

			.record {
				width: 100%;
				margin-left: 13rem;
				font-size: 18px;
				padding: 0 1rem;
				color: #b424f5;
				min-height: 5rem;
			}

			.tip {
				color: #efc574;
				font-size: 18px;
				padding: 0 2rem;
			}

			.con {
				font-size: 16px;
				padding: 1rem 2rem;
				line-height: 22px;
			}

			.h20 {
				height: 4rem;
			}

			//弹窗
			.cover {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
				z-index: 100;
			}

			.dialog-query {
				position: fixed;
				z-index: 200;
				width: 80%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				min-height: 20rem;
				background-color: #07000e;
				border-radius: 24px;
				border: solid 2px #b424f5;

				border-radius: 5px;
				font-size: 18px;

				text-align: center;
				padding: 1rem;

				.dia-top {
					width: 100%;
					height: 50px;
					font-size: 24px;
					position: relative;
					font-weight: bold;

					.t-queryresult {
						text-align: center;
						width: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}

					img {
						width: 22px;
						height: 22px;
						position: absolute;
						top: 12px;
						right: 5px;
						cursor: pointer;
					}
				}

				.item-inps {
					width: 90%;
					height: 3rem;
					background-color: #000000;
					color: #fff;
					border-radius: 0.5rem;
					border: solid 0.1rem #b424f5;
					padding: 0 0.5rem;
				}

				.num {
					font-weight: bold;
					font-size: 42px;
				}

				.txt-a {
					line-height: 2rem;
					color: #b424f5;

					.txt-b {
						color: #f3f524;
					}
				}

				.btn-boxs {
					margin: 1rem 1rem;
					text-align: center;
					line-height: 3rem;
					height: 3rem;
					background-color: #b424f5;
					border-radius: 0.5rem;
					border: solid 0rem #b424f5;
				}
			}


		}
	}
</style>
